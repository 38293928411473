<template>
  <div>
    <div class="item-bg">
      <img src="@/assets/homebg.png" alt="">
    </div>
    <HelloWorld ></HelloWorld>
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
