<template>
  <!-- <div class="home">
    <div  v-for="(item,index) in tabList" :key="index" @click="changePage(item.path)">
      <img class="img-box" :src="item.url" alt="">
    </div>
  </div> -->
  <div>
    <div class="home-bottom">
      <div class="home-btn" v-for="(item,index) in tabList" :key="index" @click="changePage(item)">
        <img :src="item.url" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  data() {
    return {
      tabList: [
        { url: require('@/assets/homebtn2.png'), name: '农家乐', path: 'agri' },
        { url: require('@/assets/homebtn1.png'), name: '美丽乡村', path: 'cun' },
        { url: require('@/assets/homebtn3.png'), name: '精品路线', path: 'line' },
      ],
    }
  },
  methods: {
    changePage(item) {
      // this.$router.push(`/${path}`)
      this.$router.push({name:'newList',query:{item:JSON.stringify(item)}})
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
  padding: 16px 0;
}
.img-box {
  width: 343px;
  height: 214px;
  margin: 0 auto 16px;
  display: block;
}
/* .img-box img {
  width: 100%;
  height: 100%;
} */
.home-bottom {
  position: fixed;
  bottom: 8%;
  width: 100%;
}
.home-btn {
  width: 335px;
  height: 62px;
  margin: 10px auto;
}
.home-btn img {
  width: 100%;
  height: 100%;
}
</style>
