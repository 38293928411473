import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import FastClick from 'fastclick'
import 'lib-flexible'

Vue.config.productionTip = false

window.addEventListener(
  function () {
    FastClick.attach(document.body)
  },
  { passive: false }
)

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
