<template>
  <div style="100%;height: 100vh;">
    <div :class="statusClass" @click="buttonClick" style="position: fixed; right: 80px; top: 50px" v-if="show"></div>
    <audio ref="music" src="@/assets/bgm.mp3" class="media-audio" loop autoplay="autoplay"></audio>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'Home',
  props: {
    murl: { type: String, default: '@/assets/bgm.mp3' },
  },
  setup() {
    //music ref
    const music = ref()
    const show = ref(true)
    //class
    const statusClass = ref('stoping')
    //点击播放按钮
    const buttonClick = () => {
      //alert('buttonClick');
      if (statusClass.value === 'stoping') {
        //开始播放
        play()
      } else {
        //停止播放
        stop()
      }
    }

    //播放
    const play = () => {
      statusClass.value = 'playing'
      music.value.play()
    }

    //停止
    const stop = () => {
      statusClass.value = 'stoping'
      music.value.pause()
    }

    return {
      buttonClick,
      music,
      show,
      statusClass,
    }
  },
}
</script>

<style scoped>
.playing {
  background: url('@/assets/bofang_1.png') no-repeat 0 0;
  width: 44px;
  height: 44px;
  animation: rotating 1.2s linear infinite;
  background-size: contain;
}
.stoping {
  background: url('@/assets/bofang.png') no-repeat 0 0;
  width: 44px;
  height: 44px;
  background-size: contain;
}
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
