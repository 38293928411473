import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '走进乡村'
    },
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/scoll',
    name: 'scoll',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Scoll.vue')
  },
  {
    path: '/cun',
    name: 'cun',
    meta: {
      title: '走进乡村-美丽乡村'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/tab/cunList.vue')
  }, {
    path: '/agri',
    name: 'agri',
    meta: {
      title: '走进乡村-农家乐'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/tab/agriList.vue')
  },
  {
    path: '/line',
    name: 'line',
    meta: {
      title: '走进乡村-精品线路'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/tab/lineList.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    meta: {
      title: '美丽乡村'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/detail/index.vue')
  },
  {
    path: '/newList',
    name: 'newList',
    meta: {
      title: ''
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/newView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
