<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
    <music ref="musicBox"></music>
  </div>
</template>
<script>
import music from './views/AboutView.vue'
export default {
  components: {
    music,
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: 'fetchDate',
  },
  methods: {
    fetchDate() {
      if (this.$route.name !== 'home') {
        this.$refs.musicBox.show = false
      } else {
        this.$refs.musicBox.show = true
      }
    },
  },
  created() {
  },
}
</script>
<style>
@import url('./assets/style/common.css');
</style>
